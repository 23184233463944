:root {
    --primary-color: #3fdfa5;
    --secondary-color: #112C47;
}

@font-face {
    font-family: 'MontserratLight';
    src: url('../assets/Montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'MontserratRegular';
    src: url('../assets/Montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'MontserratBold';
    font-weight: 600 !important;
    src: url('../assets/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'MontserratSemiBold';
    font-weight: 600 !important;
    src: url('../assets/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'MontserratAlternatesLight';
    src: url('../assets/Montserrat/MontserratAlternates-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'MontserratAlternatesBold';
    font-weight: 600 !important;
    src: url('../assets/Montserrat/MontserratAlternates-Bold.ttf') format('truetype');
}
  
body {
    font-family: 'MontserratRegular', sans-serif;
}

p {
    font-family: 'MontserratLight', sans-serif;
}

b {
    font-family: 'MontserratSemiBold', sans-serif;
}

.alpha-cert {
    font-family: 'MontserratLight', sans-serif !important;
}

.cert-bold>b {
    font-family: 'MontserratBold', sans-serif;
}

.comm {
    font-family: 'MontserratRegular', sans-serif;
}

.think {
    font-family: 'MontserratSemiBold', sans-serif;
}

.alpha-a {
    font-family: 'MontserratAlternatesLight', sans-serif;
}

b>.alpha-a {
    font-family: 'MontserratAlternatesBold', sans-serif;
}

a, a:hover, a:focus {
    color: #0a69c1 !important;
}

h1, h2, h3, h4 {
    color: var(--secondary-color) !important;
}

.nav-link {
    color: var(--secondary-color) !important;
}
  
*:focus, input, select, .select2-container--default .select2-search--dropdown .select2-search__field, span.select2-selection.select2-selection--single {
    outline-color: var(--primary-color) !important;
}

input:hover, .form-control:focus, .form-control:hover {
    border-color: var(--primary-color);
}

.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-assessment:hover {
    background: var(--primary-color);
}

.btn-secondary, .btn-secondary:hover, .btn-secondary:focus, .btn-assessment, .btn-footer-assessment {
    color: var(--secondary-color);
}

.btn-secondary, .btn-secondary:hover, .btn-secondary:focus, .btn-assessment, .btn-assessment:hover {
    border: 0.1em solid var(--secondary-color);
}

.progress-bar {
    background-color: var(--primary-color) !important;
}

#footer {
    background-color: var(--secondary-color);
}

#polygonComm {
    stroke: var(--secondary-color);
}

.comm {
    color: var(--secondary-color);
}

@media (max-width: 799px) {
    .btn-assessment:hover {     
        background: #f8f9fa;
        color: var(--secondary-color) !important;
    }
}