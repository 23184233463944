@import-normalize;

* {
    box-sizing: border-box;
}

body {
    font-size: 15px;
    width: 100vw;
    background-color: #fafafa;
}

h4, h5, h6 {
    line-height: inherit;
}

a, a:hover, a:focus {
    text-decoration: none;
    cursor: pointer;
}

#main-container-assessment {
    min-height: -webkit-fill-available;
    max-width: 1140px;
    padding: 1em;
}

.box {
    box-shadow: rgba(99, 99, 99, 0.125) 0px 2px 6px 0px;
    background-color: rgba(255, 255, 255, 0.833);
    align-items: center;
}

/* Navbar */

#nav-container {
    width: 1110px;
}

#navbar {
    z-index: 1;
    height: 4em;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 8px 0px;
}

.navbar-brand {
    padding: 0;
    margin: 0 .5rem;
}

.dropdown-menu {
    min-width: 64px;
}

#footer {
    padding: .75rem 4.5rem;
    font-size: 14px;
}

#copyright-note {
    color: #ffffff99;
}

/* Home & Introduction */

#show_video iframe {
    max-width: 640px;
    max-height: 360px;
}

#heartily-welcome {
    line-height: 1;
    padding: .8rem;
}

.text-intro {
    text-align: justify;
}

.pdf-footer {
    line-height: 40px;
    padding: 20px;
    text-align: center;
    color: #A9A9A9;
    font-size: 14px;
}

/* Assessment Forms */

.assessment-heading {
    margin-bottom: .8rem;
}

.assessment-container {
    padding: 1em 1.25rem;
    text-align: center;
    margin: auto;
}

.btn-assessment {
    width: 100%;
    margin: 1.1rem 0 !important;
    min-height: 10rem;
}

.btn-footer-assessment {
    padding: 0 .4rem !important;
    font-size: 14px;
}

.footer-assessment {
    padding: 0 1.2rem 1.8rem 1.2rem;
    font-size: 14px;
    line-height: 22px;
    color: #A9A9A9;
    text-align: center;
}

.btn-assessment:not(:hover)>.characteristic-think:not(:hover) {
    color: #444;
}

.explanation-think {
    font-size: 14px;
    margin: .5rem;
}

/* Buttons */

.btn {
    padding: 0.4em 1.3em;
    margin: 0 0.1em 0.1em 0;
    border: 0.1em solid rgba(255, 255, 255, 0);
    border-radius: 1.3em;
    color: #FFFFFF;
}

.btn:hover {
    border: 0.1em solid rgba(255, 255, 255, 1);
}

.btn:focus {
    box-shadow: 0 0 5px #3fdfa5;
}

.btn-secondary, .btn-secondary:focus, .btn-secondary:hover, .btn-assessment {
    background: #f8f9fa;
    font-weight: normal;
}

.btn-assessment:hover {
    color: #FFFFFF !important;
}

.btn-start, .btn-complete {
    border: 0.1em solid #fff;
    background-image: linear-gradient(150deg, #22CBCD 0%, #5CF27E 100%) !important;
    font-weight: bolder;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    font-size: 18px;
}

/* Bootstrap progressbar */

.progress {
    font-weight: bold;
    line-height: 2rem;
    height: 2rem;
    font-size: 0.9rem;
    width: 103%;
    margin-bottom: -1rem;
}

/* Certificates */

#cert-container {
    opacity: 0;
}

#certComm, #certThink {
    width: 1554px;
    height: 2198px;
    border: 1px solid #dfdfdf;
    filter: drop-shadow(0.2rem 0.2rem 1rem rgba(0,0,0, 0.2));
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.center {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 12px;
}

.backgroundComm {
    position: absolute;
    margin-top: 207px;
}

/* Header/Footer */

#header-cert, #main-cert, .footer-cert {
    transform: scale(2);
}

#header-cert {
    transform-origin: left top;
    margin-top: 4.4rem;
    padding-left: 3.5rem;
}

#main-cert {
    flex: 1;
    transform-origin: center top;
    padding-top: 6.35rem;
}

.footer-cert {
    position: relative;
    transform-origin: left bottom;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.75em;
    height: 6.5em;
    width: 770px;
    margin-top: auto;
  }

.footer-cert h6 {
    position: absolute;
    padding: 1.75em;
    margin-bottom: 0.1em;
    bottom: 0;
    right: 0;
}

#main-logo {
    float: left;
    height: 30px;
}

/* Assessment Values */

#polygonComm {
    z-index: 1;
}

.comm, .think {
    display: inline;
    font-size: 14px;
    margin-bottom: 0;
}

.think {
    color: white;
}

#value-container {
    z-index: 2;
    margin-top: 51px;
}

#divComm1, #divComm3 {
    margin-top: 142px;
}

#divComm2 {
    margin-top: 193px;
}

#comm0, #comm3 {
    margin-left: 208px;
}

#comm1, #comm2 {
    margin-left: 532px;
}

#thinkValueTop {
    margin-top: -177px;
    padding-bottom: 10px;
}

#thinkValueTop, #thinkValueBottom {
    width: 400px;
}

#polygonThink {
    fill: none;
    stroke: #000;
    stroke-width: 2px;
}

.loading {
    margin: 0 auto;
    text-align: center;
}

/* Internal */

.thArchive, .tdArchive {
    margin: 0;
    padding: .5rem .75rem;
}

.tdArchive {
    border: 1px solid #aaa;
}

#container-recover {
    transform: scale(0.75);
    transform-origin: left top;
}

.rs-sidenav-item, .rs-dropdown-item {
    color: rgb(50, 50, 50) !important;
}

/* Other */

.modal-dialog {
    max-width: 835px;
}

.g-btn {
    width: 100% !important;
    height: 40px !important;
    line-height: 40px !important;
    font-size: 15px !important;
}

.g-btn>div, .g-btn>div>svg {
    width: 40px !important;
    height: 38px !important;
}

react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}

.pdf-viewer {
    box-shadow: rgba(149, 157, 165, 0.25) 0px 8px 24px;
}

/* Print */

@page {
    margin-top: 0rem;
    margin-left: 0;
}

@media print {
    .navbar, .rs-sidenav, footer, .print-hide {
        display: none !important;
    }

    #main-logo {
        height: 30px !important;
    }

    #certComm {
        margin-bottom: 4rem;
    }

    #certComm , #certThink {
        border: none;
        filter: none;
    }

    #container-recover {
        width: 1120px;
        left: 2.5rem;
        position: absolute;
    }

    #cert-container {
        width: 1120px;
        top: 0;
        left: 0;
        right: 0;
        position: absolute;
        flex-direction: column !important;
    }
    
    .footer-cert-container {
        background: #fff !important;
    }
}

/* Responsive */

@media screen and (max-width: 768px), screen and (max-height: 1000px) {
    #show_video iframe {
        width: 80vw;
        height: 45vw;
    }
}

@media (max-width: 1200px) {
    .btn-assessment {
        margin: 10px;
        height: 25vh;
    }
}

@media screen and (max-width: 992px) {
    body {
        background-color: #fff !important;
    }

    .box {
        box-shadow: none !important;
    }

    #logo_header {
        height: 24px;
    }

    #heading_header {
        font-size: 15px !important;
    }

    .dropdown-toggle.nav-link {
        padding: 0 0.5rem;
    }

    h4 {
        font-size: 20px;
    }

    h5 {
        font-size: 18px;
    }

    .cert>h2 {
        font-size: 2rem;
    }

    .cert>h3 {
        font-size: 1.75rem;
    }

    p, .btn, .alert {
        font-size: 14px;
    }

    .btn-footer-assessment {
        font-size: 13px;
    }

    .page-text {
        font-size: 0.75rem;
    }

    #footer {
        font-size: 0.8rem;
        padding: 1rem;
    }

    #heartily-welcome {
        padding-bottom: 10px;
    }

    .footer-assessment {
        padding: 0;
        width: 94%;
        bottom: 33px;
        position: fixed;
    }

    .progress {
        position: fixed;
        height: 1.5rem;
        margin-bottom: 0;
        bottom: 0;
    }

    #cert-container {
        max-width: 83.33vw;
    }

    .modal-dialog {
        max-width: 100vw;
        height: 90vh;
    }
}